/* LoginPage.css */
.background {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #b5bbc7 50%, #172b4c 50%)
}

.container {
  position: relative;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 32px;
}

.background-image {
  text-align: center;
  margin-bottom: 50%;
  margin-left: 50%;
}

.card {
  position: relative;
  max-width: 345px;
  margin: auto;
  margin-top: 96px;
  /* Adjust to ensure space for the message and logo */
  z-index: 0;
}

.blueButton {
  font-size: 15px !important;
  padding: 6px 16px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: #ffc801 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}
.cardFix {
  /* width: 1000px; */
  /* margin: 0 auto; */
  margin-top: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 8px !important;
  /* position: relative;
  z-index: 10; */
}
.cardFix .search input {
  height: 10px !important;
}
.cardFix .search input:focus {
  outline: none !important;
}
.cardHeadBorder {
  border-bottom: 1px solid #ededed;
}
.cardHead {
  font-size: 25px !important;
  font-weight: 600 !important;
  /* margin-top: 20px !important; */
  padding-left: 15px !important;
  /* text-align: center !important; */
  /* margin: 0 auto; */
  /* border-bottom: 1px solid #757575; */
  margin: 0 0 20px 0;
}
.tableHead{
  background-color: #0B3E68;

}

.carousel .control-dots {
  bottom: 20px;  /* Adjust to change the position */
  z-index: 1;    /* Ensure dots are behind the content */
}
.loaderPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 16px;
  margin-top: 3px;

  /* transform: translate(-50%, 50%); */
}
.loaderPos .loaderPosition{
  top: -20%;
}
.card-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}