.Container {
  width: 100%;
  height: 70px;
  background-color: #333;
  position: sticky;
  top: 0;
  z-index: 100;
}
.Wrapper {
  height: 100%;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Logo {
  font-weight: bold;
  font-size: 20px;
  color: #eee;
  cursor: pointer;
}
.TopRight {
  display: flex;
  align-items: center;
}
.TopAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.sidebar-container {
  /* flex: 1; */
  height: 90vh;
  background-color: #222;
  position: sticky;
  top: 50;
  width: 260px;
}
.sidebar-wrapper {
  padding: 20px;
  color: #ddd;
}
.sidebar-menu {
  margin-bottom: 10px;
}
.list {
  list-style: none;
  padding: 5px;
  margin-bottom: 20px;

  .active {
    background-color: #eeeeee;
  }
}
.list-item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-size: 20px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: #333;
  }

  div {
    margin-left: 10px;
  }
}
.dashboard-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  background-color: #7a6767;
}
.dashboard-item {
  margin: 0px 20px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 1px 0px 11px -1px #000000;
  box-shadow: 1px 0px 5px -1px #000000;
  height: 20vh;
  background-color: beige;
  margin-top: 20px;
  width: 50vh;
}
.title {
  font-size: 20px;
}
.MoneyContainer {
  margin: 10px 0;
  display: flex;
  justify-content: center;

  .money {
    font-size: 30px;
    font-weight: bold;
  }

  .rate {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .icon {
    font-size: 15px;
    margin-left: 5px;
    color: green;
  }

  .icon.negative {
    color: red;
  }
}

.button-div {
  width: 100%;
  margin: 10px;
}
.button {
  background-color: gray;
  border: none;
  color: white;
  padding: 8px 38px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 800;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.form-container {
  max-width: 525px;
  margin: 0 auto;
  padding-top: 100px;
}
.form-wrap {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
}
.form-wrap h1,
.form-wrap p {
  text-align: center;
  padding: 5px 0;
}

.form-wrap .form-group {
  margin-bottom: 20px;
}

.form-wrap .form-group label {
  display: block;
  margin-bottom: 2px;
  color: rgb(87, 84, 84);
}

.form-wrap .form-group input {
  display: block;
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 6px;
  font-size: 15px;
}

.form-wrap .form-group input:focus {
  outline: 1px solid rgb(221, 182, 10);
}

.form-wrap button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: #49c1a2;
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}

.form-wrap button:hover {
  background-color: #37a08e;
  cursor: pointer;
}

.form-wrap .bottom-text {
  font-size: 12px;
}
.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .field {
  margin-bottom: 1.5rem;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
